<template>
  <tfoot v-if="!$_.isEmpty(totals)" class="blue-grey lighten-4">
    <tr
      v-for="(obTotal, sItemType) in totals"
      :key="`report.row.${sItemType}`"
      :class="{
        'blue-grey darken-4 white--text': sItemType === 'total',
      }"
    >
      <td :colspan="headers.length - $_.keys(obTotal).length - 2"></td>
      <td class="text-end" colspan="2">
        <div
          class="text-subtitle-2"
          v-text="sItemType === 'total' ? $t('amount.total') : sItemType"
        />
      </td>
      <template v-for="(sTotalValue, sTaxKey) in obTotal">
        <td :key="`report.col.${sTaxKey}`" class="text-end">
          <span v-text="sTotalValue" />
        </td>
      </template>
    </tr>
    <tr v-if="total && lastPage > 1">
      <td :colspan="headers.length" class="pa-0">
        <v-sheet class="dt-footer">
          <v-row wrap class="align-center">
            <v-col cols="12" sm="4" lg="2" class="pl-8 dt-footer__total">
              <div>
                {{ $t("total", { number: total }) }}
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              lg="8"
              v-if="lastPage > 1"
              class="dt-footer__pagination"
            >
              <v-pagination
                right
                v-model="page"
                :length="lastPage"
                :total-visible="7"
                @input="paginate"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              ></v-pagination>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              lg="2"
              class="pl-8 dt-footer__total"
            ></v-col>
          </v-row>
        </v-sheet>
      </td>
    </tr>
  </tfoot>
</template>

<script lang="ts">
import { DataTableHeader } from "@/mixins/DataTableMixin";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ReportTableFooter extends Vue {
  @Prop(Array) readonly headers!: DataTableHeader[];
  @Prop([String, Number]) readonly value!: number;
  @Prop([String, Number]) readonly total!: number;
  @Prop([String, Number]) readonly lastPage!: number;
  @Prop(Object) readonly totals!: Record<string, any>;

  get page(): number {
    return this.value;
  }

  set page(value: number) {
    this.$emit("input", value);
  }

  paginate(value: number) {
    this.$emit("input", value);
  }
}
</script>
