<template>
  <v-toolbar flat>
    <v-toolbar-title v-text="title" />
    <v-spacer />
    <v-btn
      v-if="downloadPdf"
      color="primary"
      depressed
      @click="download('pdf')"
    >
      <v-progress-circular
        v-if="!downloadPdf"
        :size="16"
        :width="2"
        class="mr-4"
        color="primary"
        indeterminate
      />
      {{ $t("report.download.pdf") }}
    </v-btn>
    <v-btn
      v-if="downloadXls"
      class="ml-2"
      color="primary"
      depressed
      @click="download('xls')"
    >
      <v-progress-circular
        v-if="!downloadXls"
        :size="16"
        :width="2"
        class="mr-4"
        color="primary"
        indeterminate
      />
      {{ $t("report.download.xls") }}
    </v-btn>
  </v-toolbar>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { ReportFormatDownloadable } from "@/composables/report";

@Component
export default class ReportToolbar extends Vue {
  @Prop(Boolean) readonly downloadPdf!: boolean;
  @Prop(Boolean) readonly downloadXls!: boolean;
  @Prop(String) readonly downloading!: ReportFormatDownloadable;
  @Prop(String) readonly title!: string;

  download(sFormat: ReportFormatDownloadable) {
    this.$emit("download", sFormat);
  }
}
</script>
