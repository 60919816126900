import { get, omitBy, replace, set, trim } from "lodash";
import { InvoiceCollection } from "@planetadeleste/vue-mc-gw";
import { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";
import Utils from "@/services/Utils";
import { Result } from "vue-mc";
import { Callback, PaginationResponse } from "@/types/utils";
import { DataTableHeader } from "@/mixins/DataTableMixin";
import { AppModule } from "@/store/app";

export type ReportFormat = "pdf" | "xls" | "csv" | "collect";
export type ReportFormatDownloadable = "pdf" | "xls" | "csv";
export type ReportCollectResponse<T = Record<string, any>> = {
  columns: string[];
  columns_right: string[];
  groups: Record<string, string[]>;
  items: PaginationResponse<T[]>;
  totals: string[];
  group_by: string | null;
  title: string | null;
  filename: string | null;
};
export interface ReportResponse {
  cachekey: string;
  filename: string;
  pdf: string;
  xls: string;
  title: string;
}
export type ReportType =
  | "sub_daily"
  | "receipt"
  | "balance"
  | "sales"
  | "unpaid";

export class ReportHelper {
  public static TYPE_SUBDAILY: ReportType = "sub_daily";
  public static TYPE_RECEIPT: ReportType = "receipt";
  private readonly _sType: ReportType;

  constructor(sType: ReportType, sFormat: ReportFormat = "collect") {
    this._sType = sType;
    this._sFormat = sFormat;
  }

  private _page = 1;

  get company() {
    return AppModule.company;
  }

  get companyDoc() {
    return this.company.firm.doc_id;
  }

  get page() {
    return this._page;
  }

  private _itemsPerPage = 20;

  get itemsPerPage() {
    return this._itemsPerPage;
  }

  private _arColumns: string[] = [];

  get arColumns() {
    return this._arColumns;
  }

  private _sFormat: ReportFormat;

  get sFormat() {
    return this._sFormat;
  }

  get isPdf() {
    return this.sFormat === "pdf";
  }

  get isXls() {
    return this.sFormat === "xls";
  }

  get isCollect() {
    return this.sFormat === "collect";
  }

  private _obFilters: Record<string, any> = {};

  get obFilters() {
    return this._obFilters;
  }

  get sType() {
    return this._sType;
  }

  get sKey(): string | undefined {
    return get(this._obFilters, "cachekey");
  }

  get arDefaultHeaders(): DataTableHeader[] {
    return [
      { text: "report.date", value: "created_at" },
      { text: "report.invoice.type", value: "invoice_type.short_name" },
      { text: "report.invoice.dgi.number", value: "order_number" },
      { text: "report.customer", value: "customer_id" },
      {
        text: "report.customer.document",
        value: "customer.firm.doc_formatted",
      },
      { text: "report.invoice.currency", value: "currency" },
      { text: "report.invoice.currency.rate", value: "rate", align: "end" },
      { text: "report.invoice.currency.convert", value: "currency_convert" },
      {
        text: "report.invoice.taxes",
        value: "taxes",
        align: "end",
      },
      {
        text: "report.total.invoice",
        value: "total_price_value",
        align: "end",
        width: 110,
      },
    ];
  }

  setFilters(obData: Record<string, any>): this {
    this._obFilters = omitBy(obData, (v) => !v);

    return this;
  }

  setFormat(sFormat: ReportFormat): this {
    this._sFormat = sFormat;

    return this;
  }

  setColumns(arColumns: string[]): this {
    this._arColumns = arColumns;

    return this;
  }

  setPage(sValue: number) {
    this._page = sValue;

    return this;
  }

  setItemsPerPage(sValue: number) {
    this._itemsPerPage = sValue;

    return this;
  }

  async collect<T = Record<string, any>>() {
    if (!this.validateFormat(this.sFormat, "collect")) {
      return;
    }

    const obResponse = await this.request<Result<ReportCollectResponse<T>>>();

    return obResponse ? obResponse.data : undefined;
  }

  async download(sFilename: string, fnCallback?: Callback) {
    if (!this.validateFormat(this.sFormat, "download")) {
      return;
    }

    const obData = await this.request<BlobPart>(true);

    if (!obData) {
      return;
    }

    sFilename = replace(trim(sFilename), "{company}", this.companyDoc);
    sFilename += `.${this.sFormat}`;

    if (fnCallback) {
      fnCallback(sFilename);
    }

    // Send blob response to browser
    Utils.downloadBlob(obData, sFilename);
  }

  async request<T = any>(isBlob = false): Promise<T | undefined> {
    const obCollection = new InvoiceCollection();
    const obResponse = await obCollection
      .createRequest(this.getConfig(isBlob))
      .send();
    const obResponseData = obResponse.response
      ? obResponse.response.data
      : undefined;

    return !!obResponseData && !!obResponseData.data
      ? obResponseData.data
      : obResponseData;
  }

  /**
   * Prepare request data
   * @param isBlob
   * @private
   */
  private getConfig(isBlob = false): AxiosRequestConfig {
    const obData = this.getData();
    const sRoute = ["collect", "pdf"].includes(this.sFormat)
      ? "reports.get"
      : "reports.download";
    const obConfig: AxiosRequestConfig = {
      url: route(sRoute, obData),
      method: "POST",
      data: obData,
    };

    if (this.isCollect) {
      obConfig.params = {
        page: this.page,
        per_page: this.itemsPerPage,
      };
    }

    if (isBlob) {
      obConfig.responseType = "blob";
    }

    return obConfig;
  }

  private getData() {
    const obData: Record<string, any> = {
      format: this.sFormat,
      type: this.sType,
      filters: this.obFilters,
      columns: this.arColumns,
      key: this.sKey,
    };

    if (this.obFilters.convert) {
      set(obData, "convert", this.obFilters.convert);
    }

    return obData;
  }

  private validateFormat(
    sFormat?: ReportFormat,
    sFor?: "collect" | "download"
  ): boolean {
    if (!sFormat) {
      sFormat = this.sFormat;
    }
    const arFormats: ReportFormat[] = ["collect", "csv", "xls", "pdf"];

    if (sFor === "collect") {
      arFormats.splice(1, 3);
    } else if (sFor === "download") {
      arFormats.splice(0, 1);
    }

    return arFormats.includes(sFormat);
  }
}

export default function useReport(sType: ReportType, sFormat: ReportFormat) {
  const obReport = new ReportHelper(sType, sFormat);

  const filters: (
    obData?: Record<string, any>
  ) => Record<string, any> | ReportHelper = (obData?: Record<string, any>) => {
    return obData ? obReport.setFilters(obData) : obReport.obFilters;
  };

  const columns: (arValue?: string[]) => ReportHelper | string[] = (
    arValue?: string[]
  ) => {
    return arValue ? obReport.setColumns(arValue) : obReport.arColumns;
  };

  const download: (
    sFilename: string,
    sFormat?: ReportFormat,
    fnCallback?: Callback
  ) => Promise<void> = async (
    sFilename: string,
    sFormat?: ReportFormat,
    fnCallback?: Callback
  ) => {
    if (sFormat && sFormat !== "collect") {
      obReport.setFormat(sFormat);
    }

    return await obReport.download(sFilename, fnCallback);
  };

  const collect = async <T = Record<string, any>>() => {
    return await obReport.setFormat("collect").collect<T>();
  };

  const request = <T = any>(isBlob = false) => obReport.request<T>(isBlob);

  return {
    collect,
    columns,
    download,
    filters,
    report: obReport,
    request,
  };
}
