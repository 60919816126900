<template>
  <v-btn
    v-if="valid"
    :to="obRoute"
    color="primary"
    target="_blank"
    :text="!button"
    v-on="$listeners"
  >
    <span v-if="label" v-text="label" class="mr-2" />
    <span class="font-weight-bold" v-text="invoice.order_serial" />
    <span v-text="invoice.order_number" />
  </v-btn>
</template>

<script lang="ts">
import { InvoiceData } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class InvoiceNumberBtn extends Vue {
  @Prop(Object) readonly invoice!: InvoiceData;
  @Prop(Boolean) readonly activator!: boolean;
  @Prop(Boolean) readonly button!: boolean;
  @Prop(String) readonly label!: string;
  @Prop({ type: String, default: () => "invoices.update" })
  readonly routeName!: string;

  get valid() {
    return (
      !!this.invoice &&
      !!this.invoice.order_serial &&
      !!this.invoice.order_number
    );
  }

  get obRoute() {
    if (!this.valid || this.activator) {
      return null;
    }

    return { name: this.routeName, params: { id: this.invoice.id } };
  }
}
</script>

<style scoped></style>
