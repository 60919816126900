import { Component, Prop, Vue } from "vue-property-decorator";
import {
  CurrencyRate,
  InvoiceData,
  InvoiceMovementTypeData,
  TaxType,
} from "@planetadeleste/vue-mc-gw";
import { DataTableHeader } from "@/mixins/DataTableMixin";
import { camelCase, find, get, hasIn, startsWith } from "lodash";
import { round, RoundByPosition } from "@/plugins/helpers";
import useInvoice, { InvoiceHelper } from "@/composables/invoice";
import { Currency } from "@planetadeleste/vue-mc-shopaholic";

interface InvoiceTaxTypeList {
  code: string;
  name: string;
  tax_amount: number;
  total_amount: number;
}

@Component
export default class ReportMixin extends Vue {
  @Prop(Object) readonly item!: InvoiceData;
  @Prop([Array, Object]) readonly headers!: DataTableHeader[];
  @Prop([Array, Object]) readonly taxes!: TaxType[];
  @Prop(Number) readonly index!: number;
  @Prop(Number) readonly reportCurrencyId!: number;
  @Prop(Boolean) readonly isSelected!: boolean;
  @Prop(Function) readonly select!: (v: boolean) => void;

  obInvoiceHelper: InvoiceHelper | undefined = undefined;

  get reportCurrency(): Currency | undefined {
    return this.obInvoiceHelper?.reportCurrency;
  }

  get currency(): Currency | undefined {
    return this.obInvoiceHelper?.currency;
  }

  get currencyRate(): undefined | CurrencyRate {
    return this.obInvoiceHelper?.currencyRate;
  }

  get obMovementType(): InvoiceMovementTypeData | undefined {
    return this.obInvoiceHelper?.obMovementType;
  }

  get increase(): boolean | undefined {
    return this.obInvoiceHelper?.increase;
  }

  get taxValueList(): InvoiceTaxTypeList[] {
    return this.obInvoiceHelper ? this.obInvoiceHelper.taxValueList : [];
  }

  get fSubtotal(): number {
    return this.obInvoiceHelper ? this.obInvoiceHelper.fSubtotal : 0;
  }

  get fTaxValue(): number {
    return this.obInvoiceHelper ? this.obInvoiceHelper.fTaxValue : 0;
  }

  get fTotal(): number {
    return this.obInvoiceHelper ? this.obInvoiceHelper.fTotal : 0;
  }

  /**
   * Get rounded total value
   *
   * @date 21/10/2022 - 08:34:17
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fTotalRounded(): number {
    return this.obInvoiceHelper ? this.obInvoiceHelper.fTotalRounded : 0;
  }

  /**
   * Get rounding value
   *
   * @date 21/10/2022 - 08:35:40
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fRoundValue(): number {
    return this.obInvoiceHelper ? this.obInvoiceHelper.fRoundValue : 0;
  }

  get iMovementType(): number {
    return this.obInvoiceHelper ? this.obInvoiceHelper.iMovementType : 0;
  }

  /**
   * Get currency setting of round by value
   *
   * @date 21/10/2022 - 08:30:47
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fRoundBy(): RoundByPosition {
    return this.obInvoiceHelper ? this.obInvoiceHelper.fRoundBy : 1;
  }

  get createdAt(): string {
    return this.obInvoiceHelper ? this.obInvoiceHelper.createdAt : "";
  }

  get signAt(): string {
    return this.obInvoiceHelper ? this.obInvoiceHelper.signAt : "";
  }

  get totalPriceValue(): string | number {
    return this.obInvoiceHelper ? this.obInvoiceHelper.totalPriceValue : 0;
  }

  get roundedPriceValue(): string | number {
    return this.obInvoiceHelper ? this.obInvoiceHelper.roundedPriceValue : 0;
  }

  get rate(): string | number {
    return this.obInvoiceHelper ? this.obInvoiceHelper.rate : 0;
  }

  /**
   * Returns item value based on header "value" value
   * @param sKey
   */
  getItemValue(sKey: string) {
    const sGetterKey = camelCase(sKey);

    if (hasIn(this, sGetterKey)) {
      return get(this, sGetterKey);
    }

    if (startsWith(sKey, "tax_")) {
      const arTaxItems = sKey.split("_");
      if (
        arTaxItems.length >= 3 &&
        (arTaxItems[2] === "value" || arTaxItems[2] === "amount")
      ) {
        return this.getTaxValue(arTaxItems[1], arTaxItems[2]);
      }
    }

    return get(this.item, sKey);
  }

  /**
   * Get tax value or tax total amount value
   * @param {string} sCode Tax code value
   * @param {"value"|"amount"} sKey Type of value to returns. value get total amount value, amount get tax amount value
   */
  getTaxValue(sCode: string, sKey: "value" | "amount"): string | number {
    const obTaxValue = find(this.taxValueList, { code: sCode });
    if (!obTaxValue) {
      return "0";
    }

    const fValue =
      sKey === "value" ? obTaxValue.total_amount : obTaxValue.tax_amount;
    return this.getCurrencyFormat(round(fValue, 2));
  }

  /**
   * Based on obData value, returns column classes
   * @param {DataTableHeader} obData
   */
  getColumnClass(obData: DataTableHeader): string {
    const arClassList = [];

    if (obData.align) {
      arClassList.push(`text-${obData.align}`);
    }

    if (obData.class) {
      arClassList.push(obData.class);
    }

    // if (!this.isSelected) {
    //   arClassList.push("opacity-40");
    // }

    return arClassList.join(" ");
  }

  /**
   * Get value in currency format, without symbol
   *
   * @param {number} fValue
   * @param {string} sCode
   */
  getCurrencyFormat(fValue: number, sCode?: string | number): string | number {
    if (this.obInvoiceHelper) {
      return this.obInvoiceHelper.getCurrencyFormat(fValue, sCode);
    }

    return 0;
  }

  created() {
    if (this.item) {
      this.obInvoiceHelper = useInvoice(this.item).obj;
      if (this.obInvoiceHelper) {
        this.obInvoiceHelper
          .setTaxes(this.taxes)
          .setReportCurrencyId(this.reportCurrencyId);
      }
    }
  }

  onSelect(v: boolean) {
    if (!this.select) {
      return;
    }

    this.select(v);
  }
}
